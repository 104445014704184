import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/services/heimberatung/header.jpg"
import BildOben from "../../images/services/heimberatung/oben.jpg"
import SubNavigation from "../../components/SubNavServices"
import Termin from "../../components/Termin"

const Heimberatung: React.FC = () => {
  const siteTitle = "Heimberatung – Dienstleistungen"
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Heimberatung"
      />

      <section className="wrapper greyColor content">
        <h1>Heimberatung</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageRow">
                <img src={BildOben} />
              </div>
            </div>
            <p class="imageCaption">Passende Sehhilfen für jede Situation</p>
          </div>
          <div className="contentColumn">
            <h2>Termin nach Vereinbarung</h2>
            <p>
              Auf Wunsch berät SCHWARZ Optik Heimbewohner, Patienten in
              Spitälern oder auch Sie einfach bei Ihnen zu Hause.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Heimberatung
